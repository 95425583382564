<template>
  <div class="row mt-4">
    <div class="col-6">
      <material-input
        id="start-date"
        v-model="startDate"
        is-required
        variant="static"
        label="Begint op"
        :max="maxStartDate"
        type="date"
      />
    </div>

    <div class="col-6">
      <material-input
        id="start-time"
        v-model="startTime"
        is-required
        variant="static"
        label="&nbsp;"
        type="time"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-6">
      <material-input
        id="end-date"
        v-model="endDate"
        is-required
        variant="static"
        label="Eindigt op"
        :min="minEndDate"
        type="date"
      />
    </div>

    <div class="col-6">
      <material-input
        id="end-time"
        v-model="endTime"
        is-required
        variant="static"
        label="&nbsp;"
        type="time"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <material-switch
        id="may-take-guest"
        v-model="mayTakeGuestHandler"
        name="may-take-guest"
      >
        Deelnemers mogen gasten meenemen
      </material-switch>
    </div>
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { watch } from 'vue';
import { createVModelHandlers } from '@/helpers/vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';

export default {
  name: 'CalendarInfo',

  components: {
    MaterialButton,
    MaterialInput,
    MaterialSwitch,
  },

  props: {
    endDateTime: {
      type: String,
      required: true,
    },
    mayTakeGuest: {
      type: Boolean,
      required: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    startDateTime: {
      type: String,
      required: true,
    },
  },

  emits: [
    'update:endDateTime',
    'update:mayTakeGuest',
    'update:startDateTime',
  ],

  data: () => ({
    temp: {
      endDate: '',
      endTime: '',
      startDate: '',
      startTime: '',
    },
  }),

  computed: {
    ...createVModelHandlers(
      'mayTakeGuest',
    ),

    endDate: {
      get() {
        return this.dateGetter('end');
      },
      set(value) {
        this.temp.endDate = value;
      },
    },

    endDateTimeObject() {
      return new Date(this.endDateTime);
    },

    endTime: {
      get() {
        return this.timeGetter('end');
      },
      set(value) {
        this.temp.endTime = value;
      },
    },

    maxStartDate() {
      return this.endDate || '';
    },

    minEndDate() {
      return this.startDate || this.TODAY;
    },

    startDate: {
      get() {
        return this.dateGetter('start');
      },
      set(value) {
        this.temp.startDate = value;
      },
    },

    startDateTimeObject() {
      return new Date(this.startDateTime);
    },

    startTime: {
      get() {
        return this.timeGetter('start');
      },
      set(value) {
        this.temp.startTime = value;
      },
    },
  },
  
  mounted() {
    this.initWatchers();
  },

  methods: {
    dateGetter(type) {
      if (this.temp[`${type}Date`]) {
        return this.temp[`${type}Date`];
      }

      if (!this[`${type}DateTime`]) {
        return '';
      }

      return this.parseDateAsDateString(this[`${type}DateTimeObject`]);
    },

    dateOrTimeChanged(type) {
      if (
        !this[`${type}Date`]
        || !this[`${type}Time`]
      ) {
        return;
      }

      this.$emit(
        `update:${type}DateTime`,
        new Date(`${this[`${type}Date`]} ${this[`${type}Time`]}`).toISOString(),
      );
    },

    initWatchers() {
      watch(
        [
          () => this.endDate,
          () => this.endTime,
        ],
        () => this.dateOrTimeChanged('end'),
      );

      watch(
        [
          () => this.startDate,
          () => this.startTime,
        ],
        () => this.dateOrTimeChanged('start'),
      );
    },

    parseDateAsDateString(date) {
      const year = date.getFullYear(),
            month = this.parseIntAs2CharacterString(date.getMonth() + 1),
            day = this.parseIntAs2CharacterString(date.getDate());

      return `${year}-${month}-${day}`;
    },

    parseIntAs2CharacterString(number) {
      return number.toString().padStart(2, '0');
    },

    timeGetter(type) {
      if (this.temp[`${type}Time`]) {
        return this.temp[`${type}Time`];
      }

      if (!this[`${type}DateTime`]) {
        return '';
      }

      const dateTimeObject = this[`${type}DateTimeObject`],
            hours = this.parseIntAs2CharacterString(dateTimeObject.getHours()),
            minutes = this.parseIntAs2CharacterString(dateTimeObject.getMinutes());

      return `${hours}:${minutes}`;
    },
  },
};
</script>