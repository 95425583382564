<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>

  <div class="col-lg-9 mt-lg-0 mt-4">
    <event-summary
      v-model:visual-image="fields.visualImage"
      v-model:visual-image-data="fields.visualImageData"
      :end-date-time="fields.endDate"
      :start-date-time="fields.startDate"
      :title="fields.title"
      :venue-name="fields.venueName"
    />

    <form @submit.prevent="submit">
      <base-fieldset id="event-active">
        <div class="row mt-4">
          <div class="col-12">
            <material-switch
              id="active"
              v-model="fields.active"
              name="active"
            >
              Actief
            </material-switch>
          </div>
        </div>
      </base-fieldset>

      <base-fieldset
        id="basic-info"
        title="Algemeen"
      >
        <basic-info
          v-model:description="fields.description"
          v-model:title="fields.title"
          v-model:url="fields.url"
          :show-update="!isNewEvent"
        />
      </base-fieldset>

      <base-fieldset
        id="calendar-info"
        title="Agenda"
      >
        <calendar-info
          v-model:end-date-time="fields.endDate"
          v-model:may-take-guest="fields.mayTakeGuest"
          v-model:start-date-time="fields.startDate"
          :show-update="!isNewEvent"
        />
      </base-fieldset>

      <base-fieldset
        id="location-info"
        title="Locatie"
      >
        <location-info
          v-model:address="fields.address"
          v-model:city="fields.city"
          v-model:location-id="fields.locationId"
          v-model:venue-name="fields.venueName"
          v-model:zip-code="fields.zipCode"
          :show-update="!isNewEvent"
        />
      </base-fieldset>

      <base-fieldset
        id="user-list"
        title="Besloten evenement"
      >
        <material-switch
          v-model="fields.isRestricted"
        >
          Besloten evenement?
        </material-switch>

        <collapse-box 
          :is-open="fields.isRestricted"
        >
          <user-list
            ref="userListRef"
            :user-ids="fields.invitedUsers"
            :show-update="!isNewEvent"  
          />
        </collapse-box>

        <material-button
          v-if="!isNewEvent"
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
        >
          Update gegevens
        </material-button>
      </base-fieldset>

      <div
        v-if="!isNewEvent"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijder dit evenement</h5>
              <p class="text-sm mb-0">
                Wanneer een evenement verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete Evenement
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <material-button
        v-if="isNewEvent"
        color="primary"
        variant="gradient"
        class="float-end mt-4 mb-0 mx-4"
      >
        Opslaan
      </material-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { add, edit, get, remove } from '@/api/providers/events';
import BaseFieldset from '@/components/Forms/BaseFieldset.vue';
import BasicInfo from '@/components/Events/Edit/BasicInfo.vue';
import CalendarInfo from '@/components/Events/Edit/CalendarInfo.vue';
import EventSummary from '@/components/Events/Edit/EventSummary.vue';
import LocationInfo from '@/components/Events/Edit/LocationInfo.vue';
import UserList from '../../components/Events/Edit/UserList.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';
import SideNav from '@/components/UI/SideNav.vue';
import CollapseBox from '../../components/UI/CollapseBox.vue';

export default {
  name: 'EventEdit',

  components: {
    BaseFieldset,
    BasicInfo,
    CalendarInfo,
    EventSummary,
    LocationInfo,
    UserList,
    MaterialButton,
    MaterialSwitch,
    SideNav,
    CollapseBox
  },

  data: () => ({
    fields: {
      active: false,
      address: '',
      city: '',
      description: '',
      endDate: '',
      locationId: '',
      mayTakeGuest: false,
      publishDate: new Date().toISOString(),
      startDate: '',
      title: '',
      url: '',
      venueName: '',
      visualImage: '',
      visualImageData: '',
      zipCode: '',
      isRestricted: false
    },
    loading: false,
  }),

  computed: {
    ...mapGetters('account', [
      'organizationId',
    ]),

    isNewEvent() {
      return this.$route.params?.id === undefined;
    },

    navigationItems() {
      return [
        {
          href: '#summary',
          icon: 'image',
          name: 'Samenvatting',
        },
        {
          href: '#basic-info',
          icon: 'notes',
          name: 'Algemeen',
        },
        {
          href: '#calendar-info',
          icon: 'event',
          name: 'Agenda',
        },
        {
          href: '#location-info',
          icon: 'location_on',
          name: 'Locatie',
        },
      ];
    },

    submitData() {
      return {
        ...this.fields,
        eventType: this.fields.isRestricted,
        organizationId: this.organizationId,
      };
    }
  },

  mounted() {
    this.loadEventData();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    async addEvent() {
      try {
        const addedEntity = await add(this.submitData);
        await this.$refs.userListRef.submit(addedEntity.id);
        this.handleSaveSuccess();
      }
      catch {
        this.handleSaveError();
      }
      this.loading = false;
    },

    async editEvent() {
      try {
        const updatedEntity = await edit(this.submitData);
        await this.$refs.userListRef.submit(updatedEntity.id);
        this.handleSaveSuccess()

      }
      catch {
        this.handleSaveError();
      }
      this.loading = false;
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.fields[key] = value;
      });
    },

    handleRemoveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is verwijderd.`,
        icon: {
          component: 'event',
        },
      });

      this.navigateToEventsOverview();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is opgeslagen.`,
        icon: {
          component: 'event',
        },
      });

      this.navigateToEventsOverview();
    },

    handleSaveError() {
      this.addNotification({
        description: `Er is een fout opgetreden tijdens het opslaan van ${this.fields.title}`,
        icon: {
          component: 'event',
        },
      });
    },

    loadEventData() {
      if (this.isNewEvent) {
        return;
      }

      get(this.$route.params.id)
        .then(this.handleGetSuccess)
        .catch((error) => error.default());
    },

    navigateToEventsOverview() {
      this.$router.push({
        name: 'events',
      });
    },

    remove() {
      if (!window.confirm(`Evenement ${this.fields.title} verwijderen?`)) {
        return;
      }

      remove(this.$route.params.id)
        .then(this.handleRemoveSuccess)
        .catch((error) => error.default());
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewEvent) {
        this.addEvent();
      } else {
        this.editEvent();
      }
    },
  },
};
</script>
